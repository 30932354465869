import React from 'react';
import './App.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCog} from '@fortawesome/free-solid-svg-icons'

class App extends React.Component {
    render() {
        return (
            <div className="mainContainer">
                <FontAwesomeIcon className={'gear-icon'} icon={faCog} size={"10x"}/>
                <div className={'name-container'}>
                    wdhays.com
                </div>
                <div className={'info-container'}>
                    Under Construction!
                </div>
                {/*<div className={'enter-container'}>*/}
                {/*    <a href="https://www.google.com/">Enter</a>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default App;
